import { navigate, graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"
import Card from "../components/card"
import Layout from "../layouts/en"
import ActivateAccountModal from "../components/modals/activate-account-modal"
import ShowPrivateKeyModal from "../components/modals/show-private-key-modal"
import { logout } from "../services/auth.service"
import { FormattedMessage } from "react-intl"
import SessionContext from "../context/session.context"
import WalletContext from "../context/wallet.context"

const AccountEnPage = ({ data: { site }, location }) => {
  const { isLoggedIn, publicKey, wipeSession } = useContext(SessionContext)
  const {
    loadWallet,
    stellarAccountIsActive,
    xlmBalance,
    devpTrustlineEnabled,
  } = useContext(WalletContext)

  const langPaths = { es: "/es/cuenta" }

  const [privateKey, setPrivateKey] = useState("")
  const [activateAccountModalIsOpen, setActivateAccountModalIsOpen] =
    useState(false)
  const [showPrivateKeyModalIsOpen, setShowPrivateKeyModalIsOpen] =
    useState(false)

  useEffect(() => {
    if (publicKey) {
      loadWallet()
    }
  }, [publicKey])

  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      {isLoggedIn && (
        <>
          <h2>
            <FormattedMessage id="accountPage.title" /> &darr;
          </h2>
          <div>
            <p>
              <FormattedMessage id="accountPage.text1" />
            </p>
            <p>
              <FormattedMessage id="accountPage.text2" />
            </p>
            <p>
              <FormattedMessage id="accountPage.text3" />
            </p>
          </div>
          <div className="grid gap-6">
            <Card>
              <div className="text-xl lg:text-2xl overflow-x-scroll md:overflow-x-auto items-start">
                {publicKey && <div>{publicKey}</div>}
              </div>
            </Card>
            {!devpTrustlineEnabled && (
              <Card>
                <Card.Row>
                  <Card.Column>
                    <FormattedMessage id="accountPage.activateAccountText" />
                  </Card.Column>
                  <Card.Column style={{ fontSize: `1.5rem`, minWidth: `25%` }}>
                    {xlmBalance} XLM
                  </Card.Column>
                  {stellarAccountIsActive && (
                    <Card.Column>
                      <button
                        className="button -primary"
                        onClick={() => setActivateAccountModalIsOpen(true)}
                      >
                        <FormattedMessage id="accountPage.activateAccountButton" />
                      </button>
                    </Card.Column>
                  )}
                </Card.Row>
              </Card>
            )}
            <Card>
              <Card.Row>
                <div className="max-w-full my-3">
                  <div className="text-xl lg:text-2xl overflow-x-scroll md:overflow-x-auto items-start">
                    <div>
                      {privateKey
                        ? privateKey
                        : "***********************************************************"}
                    </div>
                  </div>
                </div>
                {!privateKey && (
                  <div className="max-w-full md:max-w-1/3 my-3">
                    <button
                      className="button -secondary"
                      style={{ marginLeft: `auto` }}
                      onClick={() => {
                        setShowPrivateKeyModalIsOpen(true)
                      }}
                    >
                      <FormattedMessage id="accountPage.revealPrivateKeyButton" />
                    </button>
                  </div>
                )}
              </Card.Row>
            </Card>
            <Card>
              <button
                className="button -danger"
                onClick={() => {
                  logout()
                  wipeSession()
                  navigate("/")
                }}
              >
                <FormattedMessage id="accountPage.logoutButton" />
              </button>
            </Card>
          </div>
          {stellarAccountIsActive && (
            <ActivateAccountModal
              isOpen={activateAccountModalIsOpen}
              handleClose={() => setActivateAccountModalIsOpen(false)}
              handleSuccess={() => {
                setActivateAccountModalIsOpen(false)
                setTimeout(loadWallet, 5000)
              }}
            />
          )}
          <ShowPrivateKeyModal
            isOpen={showPrivateKeyModalIsOpen}
            handleClose={() => setShowPrivateKeyModalIsOpen(false)}
            handleSuccess={pk => {
              setPrivateKey(pk)
              setShowPrivateKeyModalIsOpen(false)
            }}
          />
        </>
      )}
      {!isLoggedIn && (
        <>
          <h2>
            <FormattedMessage id="accountPage.notLoggedInWarning" />
          </h2>
        </>
      )}
    </Layout>
  )
}

export default AccountEnPage
export const pageQuery = graphql`
  query accountEnPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
